import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {ThemeProvider} from '@mui/material/styles';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './App.js';
import theme from './theme.js'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ThemeProvider theme = {theme}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);

serviceWorkerRegistration.register();
